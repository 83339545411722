<template>
  <!-- eslint-disable -->
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-8 rounded">
      <h2 class="mb-4">Add New Activity</h2>
      <input v-model="newActivityName" type="text" placeholder="Activity Name" class="mb-4 p-2 border rounded w-full">
      <button @click="addActivity" class="mr-2 p-2 bg-green-500 text-white rounded">Save</button>
      <button @click="$emit('close')" class="p-2 bg-gray-500 text-white rounded">Cancel</button>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
import emitter from '../eventBus';
import axios from 'axios'
export default {
  data() {
    return {
      newActivityName: '',
    }
  },
  methods: {
    addActivity() {
      if (this.newActivityName.trim()) {
        let formData = new FormData()
        formData.append('name', this.newActivityName)

        axios
          .post(`${process.env.VUE_APP_API_URL}/admin/activities`, formData)
          .then((response) => {
            emitter.emit('add-activity', this.newActivityName)
            console.log('Activity added:', response.data)
            this.newActivityName = ''
            this.$emit('close')
          })
          .catch((error) => {
            console.error('Error adding activity:', error)
          })
      }
    },
  },
}
</script>
