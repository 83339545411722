<template>
  <!-- eslint-disable -->
  <div
    class="login-register-wrap w-full h-[100vh] flex items-center justify-center py-10 px-5 bg-[#eeeeee]"
  >
    <div id="login-register" class="w-full max-w-[500px]">
      <AuthView :mode="'register-new-babe'" />
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import AuthView from '@/components/AuthView.vue'

export default {
  name: 'RegisterView',
  components: {
    AuthView,
  },
}
/* eslint-enable */
</script>
