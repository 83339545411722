<template>
  <!-- eslint-disable -->
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-8 rounded">
      <h2 class="mb-4">Add New Nationality</h2>
      <input v-model="newActivityName" type="text" placeholder="Nationality Name" class="mb-4 p-2 border rounded w-full">
      <button @click="addActivity" class="mr-2 p-2 bg-green-500 text-white rounded">Save</button>
      <button @click="$emit('close')" class="p-2 bg-gray-500 text-white rounded">Cancel</button>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
// eslint-disable-next-line
import emitter from '../eventBus';
import axios from 'axios'
export default {
  data() {
    return {
      newActivityName: '',
    }
  },
  methods: {
    addActivity() {
      if (!this.newActivityName.trim()) {
        // Handle empty input gracefully
        /* eslint-disable */
        console.error('Error: Activity name is required.')
        return // Stop execution if name is empty
      }

      // Assuming the server expects JSON
      const payload = {
        name: this.newActivityName,
      }
      // eslint-disable-next-line
      // Log payload to check what will be sent
      /* eslint-disable */
      console.log('Sending payload:', payload)

      axios
        .post(`${process.env.VUE_APP_API_URL}/admin/nationalities`, payload)
        .then((response) => {
          /* eslint-disable */
          console.log('Activity added:', response.data)
          emitter.emit('add-activity', this.newActivityName)
          this.newActivityName = '' // Clear input after success
          this.$emit('close') // Emit close event to close modal
        })
        .catch((error) => {
          if (error.response) {
            /* eslint-disable */
            // Server responded with a status other than 2xx
            /* eslint-disable */
            console.error('Error status:', error.response.status)
            console.error('Error data:', error.response.data)
          } else if (error.request) {
            // The request was made but no response was received
            /* eslint-disable */
            console.error('Error request:', error.request)
          } else {
            // Something happened in setting up the request
            console.error('Error message:', error.message)
          }
        })
    },
  },
}
</script>
