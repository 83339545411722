<template>
  <v-container>
    <table class="elevation-1">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in babes" :key="item.id">
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.status }}</td>
          <td>
            <v-switch
              v-model="item.isActive"
              :label="item.isActive ? 'Unpublish' : 'Publish'"
              color="green"
              @change="() => updateStatus(item)"
            ></v-switch>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination-controls pt-3">
      <button @click="handlePageChange(page - 1)" :disabled="page === 1">
        Prev
      </button>
      <button
        v-for="pageNum in paginationRange"
        :key="pageNum"
        :class="{ 'active-page': pageNum === page }"
        @click="handlePageChange(pageNum)"
      >
        {{ pageNum }}
      </button>
      <button
        @click="handlePageChange(page + 1)"
        :disabled="page === totalPages"
      >
        Next
      </button>
    </div>
    <v-alert v-if="error" type="error" class="mt-3">{{ error }}</v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'BabeItemsView',
  data() {
    return {
      babes: [],
      totalItems: 0,
      page: 1,
      itemsPerPage: 10,
      error: null,
      loading: false,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    paginationRange() {
      const pages = []
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i)
      }
      return pages
    },
  },
  methods: {
    async fetchBabes() {
      this.loading = true
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/admin/babes`,
          {
            params: {
              limit: this.itemsPerPage,
              page: this.page,
            },
          }
        )
        this.babes = response.data.babes.map((babe) => ({
          ...babe,
          isActive: babe.status === 'publish',
        }))
        this.totalItems = response.data.total
        console.log('Fetched items for page:', this.page) // Debug log
      } catch (error) {
        this.error =
          'Error fetching items: ' +
          (error.response ? error.response.data.message : error.message)
      } finally {
        this.loading = false
      }
    },
    handlePageChange(newPage) {
      if (this.page !== newPage && newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage
        this.fetchBabes()
      }
    },
    updateStatus(item) {
      const newStatus = item.isActive ? 'publish' : 'draft'
      this.$axios
        .post(`${process.env.VUE_APP_API_URL}/admin/babes/updateStatus`, {
          babeId: item.id,
          status: newStatus,
        })
        .then(() => {
          item.status = newStatus
        })
        .catch((error) => {
          this.error =
            'Failed to update status: ' +
            (error.response ? error.response.data.message : error.message)
          item.isActive = !item.isActive
        })
    },
  },
  created() {
    this.fetchBabes()
  },
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  text-align: left;
  padding: 8px;
}
th {
  background-color: #f0f0f0;
}
.pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}
.pagination-controls button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.pagination-controls .active-page {
  background-color: #ccc;
}
</style>
