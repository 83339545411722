<template>
  <!-- eslint-disable -->
  <div class="login-register-inner flex flex-col gap-6">
    <h1
      class="text-[#000] font-bold text-[32px] md:text-[38px] lg:text-[46px] leading-normal"
    >
      {{ mode === 'register-new-babe' ? 'Register' : 'Login' }}
    </h1>
    <div
      v-if="errorMessage"
      class="error-message p-4 mb-4 text-red-700 bg-red-100 rounded"
    >
      {{ errorMessage }}
    </div>
    <div class="form-wrapper p-8 bg-white shadow-md rounded">
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-5">
        <div class="input-wrapper">
          <label for="username" class="sr-only">Username</label>
          <input
            type="text"
            v-model="username"
            required
            class="border py-2 px-3 w-full text-black text-body font-normal leading-relaxed"
            placeholder="Username"
          />
        </div>
        <div class="input-wrapper">
          <label for="password" class="sr-only">Password</label>
          <input
            type="password"
            v-model="password"
            required
            class="border py-2 px-3 w-full text-black text-body font-normal leading-relaxed"
            placeholder="****"
          />
        </div>
        <div v-if="mode === 'register-new-babe'" class="input-wrapper">
          <label for="email" class="sr-only">Email Address</label>
          <input
            type="email"
            v-model="email"
            required
            class="border py-2 px-3 w-full text-black text-body font-normal leading-relaxed"
            placeholder="Email Address"
          />
        </div>
        <div class="input-wrapper">
          <button
            type="submit"
            class="w-full text-black bg-gold py-2 px-3 leading-normal text-body font-semibold uppercase"
          >
            {{ mode === 'register-new-babe' ? 'Register' : 'Login' }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- eslint-enable -->
</template>
<script>
/** eslint-disable */
export default {
  name: 'AuthView',
  data() {
    return {
      username: '',
      password: '',
      email: '',
      errorMessage: '',
    }
  },
  props: ['mode'],
  methods: {
    async handleSubmit() {
      const endpoint =
        this.mode === 'register-new-babe'
          ? `${process.env.VUE_APP_API_URL}/register`
          : `${process.env.VUE_APP_API_URL}/login`
      try {
        const response = await this.$axios.post(endpoint, {
          username: this.username,
          password: this.password,
          email: this.email,
        })
        /* eslint-disable */
        console.log(response.data)
        if (this.mode === 'login') {
          /* eslint-disable */
          localStorage.setItem('userToken', response.data)
          console.log(localStorage.setItem('userToken', response.data.token))
          this.$axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${response.data.token}`
          console.log(response.data)
          if (response.data.role_id === 1) {
            this.$router.push('/dashboard/babes/')
          } else {
            this.$router.push('/profile/')
          }
        } else {
          this.$router.push('/login/')
        }
        this.errorMessage = ''
      } catch (error) {
        console.error(error.response.data.message)
        this.errorMessage = error.response.data.message
      }
    },
  },
}
/** eslint-enable */
</script>
