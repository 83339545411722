<template>
  <!-- eslint-disable -->
    <div class="app-container flex flex-col items-center p-10">
        <div class="container-none sm:container md:container lg:container xl:container mx-auto">
            <div class="w-full flex flex-col md:flex-row gap-6 md:gap-10">
                <div class="main w-full flex flex-col gap-10">
                    <div class="input-wrapper">
                        <v-text-field
                            clearable
                            label="Name"
                            v-model="name"
                        ></v-text-field>
                    </div>
                </div>
                <div class="sidebar w-full max-w-[250px]">
                    <v-card class="bg-white p-5">
                        <v-btn
                        block
                        type="submit"
                        @click.prevent="updateActivity"
                        rounded="xl"
                        size="lg"
                        class="bg-blue text-white rounded py-2 px-3"
                        >Update</v-btn
                        >
                    </v-card>
                </div>
            </div>
        </div>
    </div>
    <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
export default {
  data() {
    return {
      name: '',
    }
  },
  mounted() {
    // const babeId = this.$route.params.id
    this.getActivity()
  },
  methods: {
    async getActivity() {
      const babeId = this.$route.params.id
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/country/${babeId}`
        )
        const data = await response.json()
        console.log(data)
        this.name = data.name
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    async updateActivity() {
      //   const babeId = this.$route.params.id
      const formData = new FormData()

      try {
        for (const key in this.$data) {
          if (['image', 'imageUrls'].includes(key)) {
            continue // Skip these as they are already handled
          }
          formData.append(key, this.$data[key])
        }
      } catch (error) {
        console.error('Error', error.response ? error.response.data : error)
      }
    },
  },
}
/* eslint-enable */
</script>
