<template>
  <!-- eslint-disable -->
  <div class="app-container flex flex-col items-center p-10">
    <div
      class="container-none sm:container md:container lg:container xl:container mx-auto"
    >
      <div class="w-full flex flex-col md:flex-row gap-6 md:gap-10">
        <div class="main w-full flex flex-col gap-10">
          <div class="input-wrapper">
            <v-select
              label="Select a Babe"
              :items="babeItems"
              item-text="text"
              item-value="value"
              v-model="babe"
            ></v-select>
          </div>
          <v-sheet class="panel p-5 md:p-8 shadow-md bg-white text-left" border>
            <div class="input-wrapper">
              <quill-editor
                v-model:content="bio"
                contentType="html"
                theme="snow"
                placeholder="Bio"
              ></quill-editor>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <h2
              class="text-black text-body font-bold text-[26px] md:text-[30px] mb-5"
            >
              Information
            </h2>
            <h3
              class="text-black text-body font-bold text-[22px] md:text-[26px] mb-5 mt-5"
            >
              Address
            </h3>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Street"
                v-model="street"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="City"
                v-model="city"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="State"
                v-model="state"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Zipcode"
                v-model="zipcode"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-autocomplete
                label="Select a Country"
                :items="countries"
                v-model="country"
                autocomplete
                return-object
              ></v-autocomplete>
            </div>
            <div class="input-wrapper">
              <label for="dob">Date of Birth</label>
              <VueDatePicker
                v-model="dob"
                id="dob"
                :format="format"
              ></VueDatePicker>
            </div>
            <div class="input-wrapper">
              <label for="images">Images</label>
              <v-file-input
                v-model="images"
                chips
                multiple
                label="File input w/ chips"
                @change="handleFileChange"
                accept="image/*"
              ></v-file-input>
              <v-row class="mt-5">
                <v-col
                  v-for="(url, index) in filesUrl"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-img :src="url" aspect-ratio="1"></v-img>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <h3
              class="text-black text-body font-bold text-[22px] md:text-[26px] mb-5"
            >
              More Info
            </h3>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Nationality"
                v-model="nationality"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Height"
                v-model="height"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Weight"
                v-model="weight"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="interests"
                :items="interests_items"
                chips
                label="Interests"
                multiple
                @input="interestsHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add Interest"
                v-model="newInterest"
                @keyup.enter="addInterest"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="foods"
                :items="foods_items"
                chips
                label="Foods"
                multiple
                @input="foodsHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add food"
                v-model="newFood"
                @keyup.enter="addFood"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="drinks"
                :items="drinks_items"
                chips
                label="Drinks"
                multiple
                @input="drinksHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add drink"
                v-model="newDrink"
                @keyup.enter="addDrink"
              ></v-text-field>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <label for="availability_start">Availability Start Date</label>
            <VueDatePicker
              v-model="availability_start"
              id="availability_start"
              :format="format"
            ></VueDatePicker>
            <div class="input-wrapper">
              <label for="availability_end">Availability End Date</label>
              <VueDatePicker
                v-model="availability_end"
                id="availability_end"
                :format="format"
              ></VueDatePicker>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Price"
                prepend-icon="mdi-currency-usd"
                v-model="price"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-select
                label="Date Mode"
                v-model="date_mode"
                :items="['per date', 'per hour']"
              ></v-select>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Reviews"
                v-model="reviews"
              ></v-text-field>
            </div>
          </v-sheet>
        <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
          <div class="input-wrapper">
            <v-checkbox
              v-model="superbabe"
              label="Superbabe Status"
              color="blue"
            ></v-checkbox>
          </div>
        </v-sheet>
        </div>
        <div class="sidebar w-full max-w-[250px]">
          <v-card class="bg-white p-5">
            <v-btn
              block
              type="submit"
              @click.prevent="addBabe"
              rounded="xl"
              size="lg"
              class="bg-blue text-white rounded py-2 px-3"
              >Publish</v-btn
            >
          </v-card>
          <v-card class="bg-white p-5">
            <label>activity</label>
            <v-select
              v-model="activities"
              :items="activity_items"
              chips
              label="activities"
              multiple
              @input="activitiesHandleInput"
              :menu-props="{ maxHeight: '400' }"
              attach
            ></v-select>
            <v-text-field
              label="Add Item"
              v-model="newactivity"
              @keyup.enter="addactivity"
            ></v-text-field>
          </v-card>
        </div>
      </div>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import { ref } from 'vue'
// import VueGallerySlideshow from 'vue-gallery-slideshow'
const token = localStorage.getItem('userToken')
export default {
  components: {
    QuillEditor,
    VueDatePicker,
  },
  data() {
    return {
      babeItems: [],
      babe: '',
      bio: '',
      firstName: '',
      lastName: '',
      dob: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      countries: [],
      country: '',
      nationality: '',
      height: '',
      weight: '',
      images: [],
      filesUrl: [],
      interests: [],
      interests_items: [],
      foods: [],
      foods_items: [],
      drinks: [],
      drinks_items: [],
      availability_start: '',
      availability_end: '',
      price: '',
      date_mode: '',
      status: 'draft',
      activity_items: [],
      activities: [],
      newactivity: '',
      newInterest: '',
      newFood: '',
      newDrink: '',
      format: 'MM/dd/yyyy',
      reviews: '',
      superbabe: false,
    }
  },
  mounted() {
    console.log(`this is the ${token}`)
    this.fetchBabeUsers()
    this.fetchactivities()
    this.fetchInterests()
    this.fetchFoods()
    this.fetchDrinks()
  },
  async created() {
    this.countries = await this.getAllCountries()
  },
  methods: {
    async addBabe() {
      try {
        const formData = new FormData()

        this.images.forEach((file) => {
          formData.append('images', file, file.name)
        })

        this.status = 'publish'

        formData.append(
          'dob',
          this.dob ? moment(this.dob).format('MM/DD/YYYY') : ''
        )
        formData.append(
          'availability_start',
          this.availability_start
            ? moment(this.availability_start).format('MM/DD/YYYY')
            : ''
        )
        formData.append(
          'availability_end',
          this.availability_end
            ? moment(this.availability_end).format('MM/DD/YYYY')
            : ''
        )

        formData.append('activities', JSON.stringify(this.activities))
        formData.append('interests', JSON.stringify(this.interests))
        formData.append('foods', JSON.stringify(this.foods))
        formData.append('drinks', JSON.stringify(this.drinks))

        for (const key in this.$data) {
          if (
            [
              'babeItems',
              'countries',
              'newactivity',
              'newInterest',
              'newFood',
              'newDrink',
              'filesUrl',
              'activity_items',
              'images',
              'interests',
              'foods',
              'drinks',
              'activities',
              'interests_items',
              'drinks_items',
              'foods_items',
              'format',
              'dob',
              'availability_start',
              'availability_end',
              'firstName',
              'lastName',
              'superbabe',
            ].includes(key)
          ) {
            continue // Skip these as they are already handled
          }
          formData.append(key, this.$data[key])
        }

        console.log(formData)

        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/admin/babes`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        console.log('Success', response.data)

        this.$router
          .push(`/dashboard/babes/edit/${response.data.id}`)
          .catch((err) => {
            console.error(err)
          })
      } catch (error) {
        console.error('Error', error.response ? error.response.data : error)
      }
    },
    async getAllCountries() {
      const response = await fetch('https://restcountries.com/v3.1/all')
      const countries = await response.json()
      const sortedCountries = countries
        .map((country) => country.name.common)
        .sort()
      return sortedCountries
    },
    handleFileChange() {
      this.filesUrl = []
      for (let file of this.images) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.filesUrl.push(e.target.result)
        }
        reader.readAsDataURL(file)
      }
    },
    async fetchBabeUsers() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/user/role/2`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (!response.ok) throw new Error('Error fetching users')
        const babes = await response.json()
        this.babeItems = babes.map((babe) => ({
          title: babe.display_name,
          value: babe.id,
        }))
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchactivities() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/activities`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching activities')

        const activities = await response.json()
        this.activity_items = activities.map((activity) => activity.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchInterests() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/interests`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching interests')

        const interests = await response.json()
        this.interests_items = interests.map((interest) => interest.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchFoods() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/foods`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching foods')

        const foods = await response.json()
        this.foods_items = foods.map((food) => food.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchDrinks() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/drinks`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching drinks')

        const drinks = await response.json()
        this.drinks_items = drinks.map((drink) => drink.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    activitiesHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.activities = [...new Set([...this.activities, value])]
      }
    },
    interestsHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.interests = [...new Set([...this.interests, value])]
      }
    },
    foodsHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.foods = [...new Set([...this.foods, value])]
      }
    },
    drinksHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.drinks = [...new Set([...this.drinks, value])]
      }
    },
    async addactivity() {
      if (this.newactivity && !this.activity_items.includes(this.newactivity)) {
        try {
          const token1 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/activities`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token1}`,
              },
              body: JSON.stringify({ name: this.newactivity }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add activity')
          }

          await this.fetchactivities() // Re-fetch activities to update the list
          this.newactivity = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addInterest() {
      if (
        this.newInterest &&
        !this.interests_items.includes(this.newInterest)
      ) {
        try {
          const token2 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/interests`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token2}`,
              },
              body: JSON.stringify({ name: this.newInterest }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add activity')
          }

          await this.fetchInterests() // Re-fetch activities to update the list
          this.newInterest = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addFood() {
      if (this.newFood && !this.foods_items.includes(this.newFood)) {
        try {
          const token3 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/foods`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token3}`,
              },
              body: JSON.stringify({ name: this.newFood }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add food')
          }

          await this.fetchFoods() // Re-fetch activities to update the list
          this.newFood = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addDrink() {
      if (this.newDrink && !this.drinks_items.includes(this.newDrink)) {
        try {
          const token4 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/drinks`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token4}`,
              },
              body: JSON.stringify({ name: this.newDrink }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add drink')
          }

          await this.fetchDrinks() // Re-fetch activities to update the list
          this.newDrink = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    updateBio(value) {
      this.bio = value
    },
  },
}
/* eslint-enable */
</script>

<style scoped>
.input-wrapper + .input-wrapper {
  margin-top: 20px;
}
.input-control {
  padding: 0.5rem 0.75rem;
  background: #fff;
  border: 1px solid #eee;
  width: 100%;
  font-weight: 700;
  color: #000;
}
.input-control-title {
  font-size: 32px;
}
label {
  font-weight: 600;
  color: #000;
  margin-bottom: 8px;
  display: inline-block;
}
.multiple-items + .multiple-items {
  margin-top: 10px;
}
.add-button {
  margin-top: 20px;
}
</style>
