import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import BabeItemsNewView from '../views/babes/BabeItemNewView.vue'
import BabeItemsEditView from '../views/babes/BabeItemEditView.vue'
import BabeItemsView from '../views/babes/BabeItemsView.vue'
import ProfileView from '../views/ProfileView.vue'
import ActivitiesView from '../views/activities/ActivitiesView.vue'
import ActivitiesEditView from '../views/activities/ActivitiesEditView.vue'
import DateModeView from '../views/mode/DateModeView.vue'
import DateModeEditView from '../views/mode/DateModeEditView.vue'
import CountryView from '../views/countries/CountryView.vue'
import CountryEditView from '../views/countries/CountryEditView.vue'
import NationalitiesView from '../views/nationalities/NationalityView.vue'
import NationalitiesEditView from '../views/nationalities/NationalityEditView.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/register-new-babe',
    name: 'register',
    component: RegisterView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/dashboard/babes',
    name: 'babes',
    component: BabeItemsView,
  },
  {
    path: '/dashboard/babes/new',
    name: 'new-babe',
    component: BabeItemsNewView,
  },
  {
    path: '/dashboard/babes/edit/:id',
    name: 'edit-babe',
    component: BabeItemsEditView,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/admin/activities',
    name: 'activites',
    component: ActivitiesView,
  },
  {
    path: '/admin/activity/:id',
    name: 'edit-activity',
    component: ActivitiesEditView,
  },
  {
    path: '/admin/date-mode',
    name: 'date-mode',
    component: DateModeView,
  },
  {
    path: '/admin/date-mode/:id',
    name: 'edit-mode',
    component: DateModeEditView,
  },
  {
    path: '/admin/country',
    name: 'country',
    component: CountryView,
  },
  {
    path: '/admin/country/:id',
    name: 'edit-country',
    component: CountryEditView,
  },
  {
    path: '/admin/nationalities',
    name: 'nationality',
    component: NationalitiesView,
  },
  {
    path: '/admin/nationalities/:id',
    name: 'edit-nationality',
    component: NationalitiesEditView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
