<template>
  <!-- eslint-disable -->
  <div class="app-container flex flex-col items-center p-10">
    <div
      class="container-none sm:container md:container lg:container xl:container mx-auto"
    >
      <div class="w-full flex flex-col md:flex-row gap-6 md:gap-10">
        <div class="main w-full flex flex-col gap-10">
          <v-sheet
            class="panel p-5 md:p-8 shadow-md bg-white text-left flex flex-col gap-5"
            border
          >
            <div class="grid grid-cols-12 md:grid-cols-2 gap-5">
              <div class="input-wrapper mt-0">
                <v-text-field
                  clearable
                  label="First Name"
                  v-model="first_name"
                ></v-text-field>
              </div>
              <div class="input-wrapper mt-0">
                <v-text-field
                  clearable
                  label="Last Name"
                  v-model="last_name"
                ></v-text-field>
              </div>
            </div>
            <div class="grid grid-cols-12 md:grid-cols-2 gap-5">
              <div class="input-wrapper mt-0">
                <v-text-field
                  clearable
                  label="Username"
                  v-model="username"
                  type="text"
                  disabled
                  readonly
                ></v-text-field>
              </div>
              <div class="input-wrapper mt-0">
                <v-text-field
                  clearable
                  label="Email address"
                  v-model="email"
                  type="email"
                ></v-text-field>
              </div>
            </div>
            <div class="grid grid-cols-12 md:grid-cols-2 gap-5">
              <div class="input-wrapper mt-0">
                <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </div>
              <div class="input-wrapper mt-0">
                <v-text-field
                  v-model="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </div>
            </div>
          </v-sheet>
          <v-sheet class="panel p-5 md:p-8 shadow-md bg-white text-left" border>
            <div class="input-wrapper">
              <quill-editor
                v-model:content="bio"
                contentType="html"
                theme="snow"
                placeholder="Bio"
              ></quill-editor>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <h2
              class="text-black text-body font-bold text-[26px] md:text-[30px] mb-5"
            >
              Information
            </h2>
            <h3
              class="text-black text-body font-bold text-[22px] md:text-[26px] mb-5 mt-5"
            >
              Address
            </h3>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Street"
                v-model="street"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="City"
                v-model="city"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="State"
                v-model="state"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Zipcode"
                v-model="zipcode"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-autocomplete
                label="Select a Country"
                :items="countries"
                v-model="country"
                autocomplete
                return-object
              ></v-autocomplete>
            </div>
            <div class="input-wrapper">
              <label for="dob">Date of Birth</label>
              <VueDatePicker
                v-model="dob"
                id="dob"
                :format="format"
              ></VueDatePicker>
            </div>
            <div class="input-wrapper">
              <v-btn @click="triggerUploadDialog">Upload image</v-btn>

              <v-row class="mt-5">
                <v-col
                  v-for="(image, index) in imageUrls"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-img :src="image.name" aspect-ratio="1">
                    <v-btn icon color="red" @click="deleteImage(image.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <h3
              class="text-black text-body font-bold text-[22px] md:text-[26px] mb-5"
            >
              More Info
            </h3>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Nationality"
                v-model="nationality"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Height"
                v-model="height"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Weight"
                v-model="weight"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="interests"
                :items="interests_items"
                chips
                label="Interests"
                multiple
                @input="interestsHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add Interest"
                v-model="newInterest"
                @keyup.enter="addInterest"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="foods"
                :items="foods_items"
                chips
                label="Foods"
                multiple
                @input="foodsHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add food"
                v-model="newFood"
                @keyup.enter="addFood"
              ></v-text-field>
            </div>
            <div class="input-wrapper flex flex-col gap-5">
              <v-select
                v-model="drinks"
                :items="drinks_items"
                chips
                label="Drinks"
                multiple
                @input="drinksHandleInput"
                :menu-props="{ maxHeight: '400' }"
                attach
              ></v-select>
              <v-text-field
                label="Add drink"
                v-model="newDrink"
                @keyup.enter="addDrink"
              ></v-text-field>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <label for="availability_start">Availability Start Date</label>
            <VueDatePicker
              v-model="availability_start"
              id="availability_start"
              :format="format"
            ></VueDatePicker>
            <div class="input-wrapper">
              <label for="availability_end">Availability End Date</label>
              <VueDatePicker
                v-model="availability_end"
                id="availability_end"
                :format="format"
              ></VueDatePicker>
            </div>
          </v-sheet>
          <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Price"
                prepend-icon="mdi-currency-usd"
                v-model="price"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-select
                label="Date Mode"
                v-model="date_mode"
                :items="['per date', 'per hour']"
              ></v-select>
            </div>
          </v-sheet>
        <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
            <div class="input-wrapper">
              <v-text-field
                clearable
                label="Reviews"
                v-model="reviews"
              ></v-text-field>
            </div>
          </v-sheet>
        <v-sheet border class="panel p-5 md:p-8 shadow-md bg-white text-left">
          <div class="input-wrapper">
            <v-checkbox
              v-model="superbabe"
              label="Superbabe Status"
              color="blue"
            ></v-checkbox>
          </div>
        </v-sheet>
        </div>
        <div class="sidebar w-full max-w-[250px]">
          <v-card class="bg-white p-5">
            <v-btn
              block
              type="button"
              @click.prevent="saveBabe"
              rounded="xl"
              size="lg"
              class="bg-red text-white rounded py-2 px-3"
              >Save as draft</v-btn
            >
          </v-card>
          <v-card class="bg-white p-5">
            <v-btn
              block
              type="submit"
              @click.prevent="updateBabe"
              rounded="xl"
              size="lg"
              class="bg-blue text-white rounded py-2 px-3"
              >Update</v-btn
            >
          </v-card>
          <v-card class="bg-white p-5">
            <label>activity</label>
            <v-select
              v-model="activities"
              :items="activity_items"
              chips
              label="activities"
              multiple
              @input="activitiesHandleInput"
              :menu-props="{ maxHeight: '400' }"
              attach
            ></v-select>
            <v-text-field
              label="Add Item"
              v-model="newactivity"
              @keyup.enter="addactivity"
            ></v-text-field>
          </v-card>
        </div>
      </div>
    </div>
  </div>
  <upload-dialog></upload-dialog>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import { ref } from 'vue'
// import VueGallerySlideshow from 'vue-gallery-slideshow'

import UploadDialog from './UploadDialog.vue'
import emitter from './eventBus'
export default {
  components: {
    QuillEditor,
    VueDatePicker,
    UploadDialog,
  },
  data() {
    return {
      babeItems: [],
      babeId: '',
      first_name: '',
      last_name: '',
      email: '',
      userId: '',
      username: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      babe: '',
      bio: '',
      dob: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      countries: [],
      country: '',
      nationality: '',
      height: '',
      weight: '',
      images: [],
      interests: [],
      interests_items: [],
      foods: [],
      foods_items: [],
      drinks: [],
      drinks_items: [],
      availability_start: '',
      availability_end: '',
      price: '',
      date_mode: '',
      status: 'draft',
      activity_items: [],
      activities: [],
      newactivity: '',
      newInterest: '',
      newFood: '',
      newDrink: '',
      format: 'MM/dd/yyyy',
      imageUrls: [],
      reviews: '',
      superbabe: false,
    }
  },
  mounted() {
    // const babeId = this.$route.params.id
    const token = localStorage.getItem('userToken')
    console.log(`this is the ${token}`)
    this.getUserIdFromToken(token)
    this.fetchBabeUsers()
    this.fetchactivities()
    this.fetchInterests()
    this.fetchFoods()
    this.fetchDrinks()
    emitter.on('upload-complete', (babeId) => {
      this.fetchBabeById(babeId)
    })
  },
  beforeDestroy() {
    emitter.off('upload-complete', this.fetchBabeById) // Clean up to prevent memory leaks
  },
  async created() {
    this.countries = await this.getAllCountries()
  },
  methods: {
    async getUserIdFromToken(token) {
      if (!token) {
        console.error('No token provided')
        return null
      }

      try {
        // Split the token into its three parts (Header, Payload, Signature)
        const base64Url = token.split('.')[1]
        // Replace token specific base64 characters and decode
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        // Decode base64 payload
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )

        // Parse the payload to an object
        const payload = JSON.parse(jsonPayload)

        // Extract and return the user ID
        const userId = payload.username // Adjust the payload key according to your token structure
        console.log(userId)

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/profile/${userId}`
        )

        const user = await response.json()
        console.log('user: ', user)
        this.first_name = user.user.first_name ?? ''
        this.last_name = user.user.last_name ?? ''
        this.email = user.user.email
        this.username = user.user.username
        this.userId = user.user.id
        console.log('user id:', this.userId)
        if (user.babeIds && user.babeIds.length > 0) {
          this.babeId = user.babeIds[0]
          console.log(this.babeId)
          this.fetchBabeById(this.babeId)
        }
      } catch (e) {
        console.error('Error decoding token:', e)
        return null
      }
    },
    async fetchBabeById() {
      console.log('fetching babe with id:', this.babeId)
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/babes/${this.babeId}`
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        console.log(data)
        this.bio = data.bio ?? ''
        this.street = data.street ?? ''
        this.city = data.city ?? ''
        this.state = data.state ?? ''
        this.zipcode = data.zipcode ?? ''
        this.dob = data.dob ?? ''

        this.imageUrls = data.images
        this.height = data.height ?? ''
        this.weight = data.weight ?? ''
        this.price = data.price
        this.availability_start = data.availability_start
        this.availability_end = data.availability_end
        this.activities = data.activities
        this.interests = data.interests
        this.foods = data.foods
        this.drinks = data.drinks
        this.country = data.country
        this.nationality = data.nationality ?? []
        this.date_mode = data.date_mode
        this.babe = data.display_name
        this.reviews = data.reviews
        this.superbabe = Boolean(data.superbabe)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    async saveBabe() {
      const babeId = this.babeId
      console.log('first name', this.userId)
      const tokenUp = localStorage.getItem('userToken')
      try {
        const formData = new FormData()

        // Status might be different for update
        this.status = 'draft'

        // The rest of the data appending remains similar
        formData.append(
          'dob',
          this.dob ? moment(this.dob).format('MM/DD/YYYY') : ''
        )
        formData.append(
          'availability_start',
          this.availability_start
            ? moment(this.availability_start).format('MM/DD/YYYY')
            : ''
        )
        formData.append(
          'availability_end',
          this.availability_end
            ? moment(this.availability_end).format('MM/DD/YYYY')
            : ''
        )
        formData.append('activities', JSON.stringify(this.activities))
        formData.append('interests', JSON.stringify(this.interests))
        formData.append('foods', JSON.stringify(this.foods))
        formData.append('drinks', JSON.stringify(this.drinks))
        formData.append('first_name', this.first_name)
        formData.append('last_name', this.last_name)
        formData.append('email', this.email)
        formData.append('password', this.password)
        formData.append('userId', this.userId)

        // Looping through $data to append other fields
        for (const key in this.$data) {
          if (
            [
              'babeId',
              'babeItems',
              'countries',
              'newactivity',
              'newInterest',
              'newFood',
              'newDrink',
              'imageUrls',
              'activity_items',
              'images',
              'interests',
              'foods',
              'drinks',
              'activities',
              'interests_items',
              'drinks_items',
              'foods_items',
              'format',
              'dob',
              'availability_start',
              'availability_end',
              'first_name',
              'last_name',
              'email',
              'password',
              'confirmPassword',
              'showPassword',
              'showConfirmPassword',
              'userId',
              'username',
            ].includes(key)
          ) {
            continue // Skip these as they are already handled
          }
          formData.append(key, this.$data[key])
        }

        console.log(formData)

        // Use PUT or PATCH method for update, depending on API specification
        const response = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/profile/${babeId}`, // Change URL to include babeId for update
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${tokenUp}`,
            },
          }
        )
        console.log('Success', response.data)
      } catch (error) {
        console.error('Error', error.response ? error.response.data : error)
      }
    },
    async updateBabe() {
      const babeId = this.babeId
      console.log('first name', this.userId)
      const tokenUp = localStorage.getItem('userToken')
      try {
        const formData = new FormData()

        // Status might be different for update
        this.status = 'publish'

        // The rest of the data appending remains similar
        formData.append(
          'dob',
          this.dob ? moment(this.dob).format('MM/DD/YYYY') : ''
        )
        formData.append(
          'availability_start',
          this.availability_start
            ? moment(this.availability_start).format('MM/DD/YYYY')
            : ''
        )
        formData.append(
          'availability_end',
          this.availability_end
            ? moment(this.availability_end).format('MM/DD/YYYY')
            : ''
        )
        formData.append('activities', JSON.stringify(this.activities))
        formData.append('interests', JSON.stringify(this.interests))
        formData.append('foods', JSON.stringify(this.foods))
        formData.append('drinks', JSON.stringify(this.drinks))
        formData.append('first_name', this.first_name)
        formData.append('last_name', this.last_name)
        formData.append('email', this.email)
        formData.append('password', this.password)
        formData.append('userId', this.userId)

        const superbabeValue = this.superbabe ? 1 : 0;
        formData.append('superbabe', superbabeValue);

        // Looping through $data to append other fields
        for (const key in this.$data) {
          if (
            [
              'babeId',
              'babeItems',
              'countries',
              'newactivity',
              'newInterest',
              'newFood',
              'newDrink',
              'imageUrls',
              'activity_items',
              'images',
              'interests',
              'foods',
              'drinks',
              'activities',
              'interests_items',
              'drinks_items',
              'foods_items',
              'format',
              'dob',
              'availability_start',
              'availability_end',
              'first_name',
              'last_name',
              'email',
              'password',
              'confirmPassword',
              'showPassword',
              'showConfirmPassword',
              'userId',
              'username',
              'superbabe',
            ].includes(key)
          ) {
            continue // Skip these as they are already handled
          }
          formData.append(key, this.$data[key])
        }

        console.log(formData)

        // Use PUT or PATCH method for update, depending on API specification
        const response = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/profile/${babeId}`, // Change URL to include babeId for update
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${tokenUp}`,
            },
          }
        )
        console.log('Success', response.data)
      } catch (error) {
        console.error('Error', error.response ? error.response.data : error)
      }
    },

    async getAllCountries() {
      const response = await fetch('https://restcountries.com/v3.1/all')
      const countries = await response.json()
      const sortedCountries = countries
        .map((country) => country.name.common)
        .sort()
      return sortedCountries
    },
    async handleFileChange() {
      const babeId = this.babeId
      const formData = new FormData()
      const tokenI = localStorage.getItem('userToken')
      this.images.forEach((file) => {
        formData.append('images', file, file.name)
      })
      formData.append('babeId', babeId)

      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/admin/image`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${tokenI}`,
            },
          }
        )
        console.log('Success:', response.data)
      } catch (error) {
        console.error('Error:', error.response.data)
      }
    },
    async fetchBabeUsers() {
      const tokenU = localStorage.getItem('userToken')
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/user/role/2`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenU}`,
            },
          }
        )
        if (!response.ok) throw new Error('Error fetching users')
        const babes = await response.json()
        this.babeItems = babes.map((babe) => ({
          title: babe.display_name,
          value: babe.id,
        }))
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchactivities() {
      const tokenA = localStorage.getItem('userToken')
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/activities`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenA}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching activities')

        const activities = await response.json()
        this.activity_items = activities.map((activity) => activity.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchInterests() {
      const tokenIn = localStorage.getItem('userToken')
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/interests`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenIn}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching interests')

        const interests = await response.json()
        this.interests_items = interests.map((interest) => interest.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchFoods() {
      const tokenF = localStorage.getItem('userToken')
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/foods`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenF}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching foods')

        const foods = await response.json()
        this.foods_items = foods.map((food) => food.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    async fetchDrinks() {
      const tokenD = localStorage.getItem('userToken')
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/drinks`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenD}`,
            },
          }
        ) // Adjust the URL to your API endpoint
        if (!response.ok) throw new Error('Error fetching drinks')

        const drinks = await response.json()
        this.drinks_items = drinks.map((drink) => drink.name) // Adjust this if your activity structure is different
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    activitiesHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.activities = [...new Set([...this.activities, value])]
      }
    },
    interestsHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.interests = [...new Set([...this.interests, value])]
      }
    },
    foodsHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.foods = [...new Set([...this.foods, value])]
      }
    },
    drinksHandleInput(value) {
      if (value && !this.items.includes(value)) {
        this.items.push(value)
        this.drinks = [...new Set([...this.drinks, value])]
      }
    },
    async addactivity() {
      // const tokenAc = localStorage.getItem('userToken')
      if (this.newactivity && !this.activity_items.includes(this.newactivity)) {
        try {
          const token1 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/activities`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token1}`,
              },
              body: JSON.stringify({ name: this.newactivity }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add activity')
          }

          await this.fetchactivities() // Re-fetch activities to update the list
          this.newactivity = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addInterest() {
      if (
        this.newInterest &&
        !this.interests_items.includes(this.newInterest)
      ) {
        try {
          const token2 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/interests`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token2}`,
              },
              body: JSON.stringify({ name: this.newInterest }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add activity')
          }

          await this.fetchInterests() // Re-fetch activities to update the list
          this.newInterest = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addFood() {
      if (this.newFood && !this.foods_items.includes(this.newFood)) {
        try {
          const token3 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/foods`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token3}`,
              },
              body: JSON.stringify({ name: this.newFood }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add food')
          }

          await this.fetchFoods() // Re-fetch activities to update the list
          this.newFood = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    async addDrink() {
      if (this.newDrink && !this.drinks_items.includes(this.newDrink)) {
        try {
          const token4 = localStorage.getItem('userToken')
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/admin/drinks`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token4}`,
              },
              body: JSON.stringify({ name: this.newDrink }),
            }
          )

          if (!response.ok) {
            throw new Error('Failed to add drink')
          }

          await this.fetchDrinks() // Re-fetch activities to update the list
          this.newDrink = '' // Reset the input field
        } catch (error) {
          console.error('Error:', error)
          // Handle error
        }
      }
    },
    updateBio(value) {
      this.bio = value
    },
    async deleteImage(imageIndex) {
      console.log(imageIndex) // Ensure the index is valid
      // console.log(imageUrls);
      const imageId = imageIndex
      const babeId = this.babeId

      console.log(imageId)

      if (this.imageUrls.length <= 1) {
        return
      }

      try {
        await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/admin/image/${imageId}`,
          {
            data: { babeId: babeId },
            headers: { 'Content-Type': 'application/json' },
          }
        )

        // console.log(data);
        // this.images.splice(imageIndex, 1);
        this.imageUrls.splice(imageIndex, 1)
        // this.imageIds.splice(imageIndex, 1);
        this.fetchBabeById()
      } catch (error) {
        console.error(
          'Error deleting image:',
          error?.response?.data || error.message
        )
      }
    },
    triggerUploadDialog() {
      const babeID = this.babeId
      emitter.emit('open-upload-dialog', babeID)
    },
  },
}
/* eslint-enable */
</script>

<style scoped>
.input-wrapper + .input-wrapper {
  margin-top: 20px;
}
.input-control {
  padding: 0.5rem 0.75rem;
  background: #fff;
  border: 1px solid #eee;
  width: 100%;
  font-weight: 700;
  color: #000;
}
.input-control-title {
  font-size: 32px;
}
label {
  font-weight: 600;
  color: #000;
  margin-bottom: 8px;
  display: inline-block;
}
.multiple-items + .multiple-items {
  margin-top: 10px;
}
.add-button {
  margin-top: 20px;
}
</style>
