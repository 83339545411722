<template>
  <!-- eslint-disable -->
    <div class="app-container flex flex-col items-center p-10">
        <div class="container-none sm:container md:container lg:container xl:container mx-auto">
            <div class="w-full flex flex-col md:flex-row gap-6 md:gap-10">
                <div class="main w-full flex flex-col gap-10">
                    <div class="input-wrapper">
                        <v-text-field
                            clearable
                            label="Name"
                            v-model="name"
                        ></v-text-field>
                    </div>
                    <div class="input-wrapper">
                        <v-btn @click="triggerUploadDialog">Upload image</v-btn>

                        <v-row class="mt-5" v-if="imageUrls.length > 0">
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-img :src="imageUrls" aspect-ratio="1">
                                <v-btn icon color="red" @click="deleteImage(imageUrls[0].id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                </v-img>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="sidebar w-full max-w-[250px]">
                    <v-card class="bg-white p-5">
                        <v-btn
                        block
                        type="submit"
                        @click.prevent="updateActivity"
                        rounded="xl"
                        size="lg"
                        class="bg-blue text-white rounded py-2 px-3"
                        >Update</v-btn
                        >
                    </v-card>
                </div>
            </div>
        </div>
    </div>
    <upload-dialog></upload-dialog>
    <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
import UploadDialog from '../UploadActivity.vue'
import emitter from '../eventBus'
export default {
  components: {
    UploadDialog,
  },
  data() {
    return {
      name: '',
      image: '',
      imageUrls: '',
    }
  },
  mounted() {
    // const babeId = this.$route.params.id
    this.getActivity()
    emitter.on('upload-complete', (babeId) => {
      this.getActivity(babeId)
    })
  },
  beforeUnmount() {
    emitter.off('upload-complete', this.getActivity) // Clean up to prevent memory leaks
  },
  methods: {
    async getActivity() {
      const babeId = this.$route.params.id
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/activities/${babeId}`
        )
        const data = await response.json()
        console.log(data)
        this.name = data.name
        this.imageUrls = data.image ? data.image : []
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    async updateActivity() {
      // const babeId = this.$route.params.id
      const formData = new FormData()

      try {
        for (const key in this.$data) {
          if (['image', 'imageUrls'].includes(key)) {
            continue // Skip these as they are already handled
          }
          formData.append(key, this.$data[key])
        }
      } catch (error) {
        console.error('Error', error.response ? error.response.data : error)
      }
    },
    triggerUploadDialog() {
      const babeID = this.$route.params.id
      emitter.emit('open-upload-dialog', babeID)
    },
  },
}
/* eslint-enable */
</script>
