<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290px">
      <v-card>
        <v-card-title>Upload File</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="files"
            chips
            multiple
            label="Select file"
            @change="handleFileUpload"
            accept="image/*"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" text @click="uploadFile">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, onUnmounted, inject } from 'vue'
import emitter from './eventBus'
import axios from 'axios'

export default {
  setup() {
    const dialog = ref(false)
    const files = ref(null) // Define the file ref
    const babeID = ref(null) // Define babeID ref

    // Inject fetchBabeById method if it's provided in a parent component or plugin
    const getActivity = inject('getActivity', () => {})

    function openDialog(id) {
      // Make sure to accept the 'id' parameter
      dialog.value = true
      babeID.value = id // Set the babeID when the dialog is opened
    }

    function closeDialog() {
      dialog.value = false
      files.value = null // Reset file input
    }

    function handleFileUpload() {
      // Handle file selection
    }

    async function uploadFile() {
      const formData = new FormData()
      files.value.forEach((file) => {
        formData.append('image', file, file.name)
      })

      formData.append('babeId', babeID.value)

      try {
        const response = await axios.put(
          `${process.env.VUE_APP_API_URL}/admin/activities/${babeID.value}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        console.log('Success:', response.data)

        closeDialog() // Close the dialog upon successful upload
        getActivity(babeID.value) // Reload the babe data
        emitter.emit('upload-complete', babeID.value)
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error)
      }
    }

    onMounted(() => {
      emitter.on('open-upload-dialog', openDialog)
    })

    onUnmounted(() => {
      emitter.off('open-upload-dialog', openDialog)
    })

    return {
      dialog,
      files,
      openDialog,
      closeDialog,
      handleFileUpload,
      uploadFile,
    }
  },
}
/* eslint-enable */
</script>
