<template>
  <!-- eslint-disable -->
  <div class="app-container flex flex-col items-center p-10">
    <div
      class="container-none sm:container md:container lg:container xl:container mx-auto"
    >
      <button @click="showAddModal = true" class="mb-4 p-2 bg-blue-500 text-white rounded">Add New Country</button>
      <div class="activities flex flex-col gap-5 striped">
        <div
          v-for="(item, index) in activities"
          :key="index - item.id"
          class="activity flex w-full flex-row justify-between gap-5 items-center py-2 px-4"
        >
          <div class="activity-name">{{ item.name }}</div>
          <div class="activity-actions flex flex-row gap-4 items-center">
            <router-link :to="`/admin/country/${item.id}`"
              class="flex flex-col justify-center items-center rounded-full w-8 h-8 bg-[#00ff00]"
            >
              <v-icon class="text-base"> mdi-pencil </v-icon>
            </router-link>
            <div
              class="flex flex-col justify-center items-center rounded-full w-8 h-8 bg-[#ff0e0e]"
            >
              <v-icon class="text-base text-white"> mdi-close </v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddCountryModal v-if="showAddModal" @close="showAddModal = false"></AddCountryModal>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
import emitter from '../eventBus';
import AddCountryModal from './AddCountryModal.vue'
export default {
  components: {
    AddCountryModal,
  },
  data() {
    return {
      showAddModal: false,
      activities: [],
    }
  },
  mounted() {
    this.getAllActivities()
    emitter.on('add-activity', () => {
      this.getAllActivities()
    })
  },
  beforeUnmount() {
    emitter.off('add-activity')
  },
  methods: {
    async getAllActivities() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/admin/country`
        )
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()

        this.activities = data
        console.log(data)
        console.log(this.activities)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    addActivity(activity) {
      this.activities.push(activity)
    },
  },
}
</script>
